import React from 'react';

const Filter = ({ onFilterChange }) => {
  return (
    <div className="filter-container">
      <label className="filter-label">
        Restaurant:
        <select className="filter-select" onChange={(e) => onFilterChange('restaurant', e.target.value)}>
          <option value="">All</option>
          <option value="Subway">Subway</option>
          <option value="Greggs">Greggs</option>
          <option value="KFC">KFC</option>
          <option value="McDonalds">McDonald's</option>
        </select>
      </label>
      {/* Add more filters as needed */}
    </div>
  );
};

export default Filter;