import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake, faFire } from '@fortawesome/free-solid-svg-icons';

const Item = ({ name, description, price, restaurant, temperature }) => {
  return (
      <div className="item">
        <h3>{name}</h3>
        <p>{restaurant}</p> <br/>
        <p>{description}</p>
        <p className="item-price">{price}</p>

        {/* Only show temperature icon if a temperature is provided */}
        {temperature && (
          <div className="item-temp">
            {temperature === 'Hot' ? (
              <FontAwesomeIcon icon={faFire} className="temperature-hot" />
            ) : (
              <FontAwesomeIcon icon={faSnowflake} className="temperature-cold" />
            )}
          </div>
        )}
      </div>
  );
};

export default Item;