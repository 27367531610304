import React, { useState } from 'react';
import SearchBar from './components/SearchBar';
import Filter from './components/Filter';
import ItemList from './components/ItemList';
import Header from './components/Header';
import './App.css';
import menuItems from './components/MenuItems';

const App = () => {
  const [filter, setFilter] = useState({});
  const [query, setQuery] = useState('');
  const [items] = useState(menuItems);

    

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
  };

  const handleFilterChange = (filterType, filterValue) => {
    setFilter({ ...filter, [filterType]: filterValue });
  };

  const filteredItems = items.filter((item) => {
    // Basic search and filter logic
    const matchesQuery = item.name.toLowerCase().includes(query.toLowerCase());
    const matchesRestaurant = filter.restaurant ? item.restaurant === filter.restaurant : true;
    return matchesQuery && matchesRestaurant;
  });

  return (
    <div>
      <Header/>
      <br/><br/><br/><br/><br/>
      <Filter onFilterChange={handleFilterChange} /> 
      <SearchBar onSearch={handleSearch} />
      <ItemList items={filteredItems} />
      
    </div>
  );
};

export default App;