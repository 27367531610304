import React, { useState } from 'react';
import logo from './wolf.png';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState(null);
  const [missingItems, setMissingItems] = useState(1);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setInputValue('');
    setResult(null);
  };

  const handleOpenSecondModal = () => setIsSecondModalOpen(true);
  const handleCloseSecondModal = () => {
    setIsSecondModalOpen(false);
    setInputValue('');
    setResult(null);
  };

  const handleCalculate = () => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      setResult(value * 0.5);
    }
  };

  const handleCalculateSecondModal = () => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      if (missingItems === 1) {
        setResult(value * 0.33);
      } else if (missingItems === 2) {
        setResult(value * 0.66);
      }
    }
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="header-logo">
        <img src={logo} alt="Logo" className="logo" />
        </div>
          <div className="header-buttons">
            <button className="header-button" onClick={handleOpenModal}>
              BOGOF Calculator
            </button>
            <button className="header-button" onClick={handleOpenSecondModal}>
              3 for 2 Calculator
            </button>
          </div>
        </div>

      {/* First Modal: 50% Calculator */}
      <div className={`modal ${isModalOpen ? 'show' : ''}`}>
        <div className="modal-content">
          <h2>50% Calculator</h2>
          <input
            type="number"
            placeholder="Enter amount £"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <br></br>
          {result !== null && <p> £{result}</p>}
          <button onClick={handleCalculate} className="modal-button">
            Calculate
          </button>
          <button onClick={handleCloseModal} className="modal-close-button">
            Close
          </button>
        </div>
      </div>

      {/* Second Modal: Missing Items Calculator */}
      <div className={`modal ${isSecondModalOpen ? 'show' : ''}`}>
        <div className="modal-content">
          <h2>3 for 2 Calculator</h2>
          <input
            type="number"
            placeholder="Enter amount £"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div className="modal-options">
            <label>
              <input
                type="radio"
                value={1}
                checked={missingItems === 1}
                onChange={() => setMissingItems(1)}
              />
              1 Item Missing (33%)
            </label>
            <label>
              <input
                type="radio"
                value={2}
                checked={missingItems === 2}
                onChange={() => setMissingItems(2)}
              />
              2 Items Missing (66%)
            </label>
          </div>
          <button onClick={handleCalculateSecondModal} className="modal-button">
            Calculate
          </button>
          {result !== null && (
            <p>£{result}</p>
          )}
          <button onClick={handleCloseSecondModal} className="modal-close-button">
            Close
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;