import React from "react";

const SearchBar = ({ onSearch }) => {
    return (
        <div className="search-bar-container">
            <input
                type="text"
                placeholder="Look up Items"
                onChange={(e) => onSearch(e.target.value)}
                className="search-bar"
            />
        </div>
    );
};

export default SearchBar;